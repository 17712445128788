(function ($) {
  Drupal.behaviors.hotspotProductGridFormatterV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $template = $('.js-hotspot-product-grid-formatter-v1', context);

      $template.each(function () {
        var $this = $(this);
        var $hotspot = $this.find('.js-hotspot');
        var $hotProduct = $this.find('.js-hotspot-product__card');
        var $closer = $hotProduct.find('.js-hotspot-product__card-close');

        if (!$hotProduct.length) {
          return;
        }
        // open
        $hotspot.once().on('click', function () {
          var $this = $(this);

          $hotspot.attr('aria-expanded', 'false');
          $hotProduct.removeClass('active').attr('aria-hidden', 'true');
          $this.attr('aria-expanded', 'true').next('.js-hotspot-product__card').addClass('active').attr('aria-hidden', 'false');
        });
        // close
        $closer.once().on('click', function (e) {
          e.preventDefault();
          var $this = $(this);

          $this.closest('.js-hotspot').attr('aria-expanded', 'false');
          $this.closest('.js-hotspot-product__card').removeClass('active').attr('aria-hidden', 'true');
        });
      });
    },
    attached: false
  };
})(jQuery);
